import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'

import { HelmetSEO } from '../../../components/Helmet/HelmetSEO'
import './teamForm.scss'

import Base from '../../../statics/T&C-Páginas-Web-para-Registro-Team-Verano-Cristal.pdf'
import { ValidarEdad } from '../../../components/ValidarEdad/ValidarEdad'

export const TeamForm = () => {
  const [datos, setDatos] = useState({
    name: '',
    email: '',
    message: '',
  })

  const updateInput = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    event.target.reset()

    fetch('https://hooks.zapier.com/hooks/catch/2792106/b1udbcx/', {
      method: 'POST',
      body: JSON.stringify(datos),
    })
      .then(async () => {
        Swal.fire({
          title: '¡Ya estas participando!',
        })
        // console.log('Se envió')
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: '¡Algo sucedió!',
        })
        // console.log('Algo sucedió')
      })
  }

  return (
    <>
      <HelmetSEO title={'Formulario Team Verano'} />

      <ValidarEdad />

      <div className="TeamForm__hero">
        <div className="TeamForm__hero__content">
          <div className="Hero__content__top"></div>

          <div className="Hero__content__bottom">
            <div className="Content__bottom__logo">
              <div className="Bottom__logo__center">
                <NavLink to="/">
                  <img
                    loading="lazy"
                    src="https://user-images.githubusercontent.com/62721491/147164335-b925d1fa-53e8-451b-8cea-24af73f4ee01.png"
                    alt="logo team Cristal"
                  />
                </NavLink>
              </div>
              <div className="Bottom__logo__text">
                <h1>El Equipo Cristal</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="TeamForm__competition">
        <div className="TeamForm__competition__content">
          <div className="Competition__title__text">
            <h1>
              ¿Qué es lo primero que se te viene a la mente cuando escuchas
              Cristal?
            </h1>
          </div>
          <div className="Content__buttons">
            <form onSubmit={handleSubmit}>
              <div className="Form__input__group">
                <div className="Form__input">
                  <input
                    type="text"
                    name="name"
                    placeholder="Nombre y Apellido:"
                    onChange={updateInput}
                    required
                  />
                </div>
                <div className="Form__input">
                  <input
                    type="email"
                    name="email"
                    placeholder="E-mail:"
                    onChange={updateInput}
                    required
                  />
                </div>
              </div>
              <textarea
                type="text"
                name="message"
                placeholder="Tu respuesta..."
                onChange={updateInput}
                required
              ></textarea>
              <div className="Form-checkbox">
                <input
                  type="checkbox"
                  required
                />
                <label htmlFor="">
                  <span>Acepto los términos y condiciones</span>
                  <a href={Base} target="_blank" rel="noopener noreferrer">
                    Ver bases
                  </a>
                </label>
              </div>
              <div className="Form__action">
                <button type="submit">Enviar</button>
              </div>
              <div className="Form__button">
                <NavLink to="/ruta-del-equipo-cristal">
                  <button>Sigue la ruta del Equipo Cristal</button>
                </NavLink>
              </div>
            </form>
          </div>
        </div>

        <div className="Form__footer">
          <img
            loading="lazy"
            src="https://user-images.githubusercontent.com/62721491/147702241-20125f13-b06e-49cf-b961-4019852e70b3.png"
            alt="logo team Cristal form"
          />
        </div>
      </div>
    </>
  )
}
