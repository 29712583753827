import React from 'react'

import { HelmetSEO } from '../../components/Helmet/HelmetSEO'
import { BeerCard } from '../../components/BeerCard/BeerCard'

import { ValidarEdad } from '../../components/ValidarEdad/ValidarEdad'

import './nuestra-cerveza.scss'

import cardLager1 from '../../statics/images/NuestrasCervezas/c-1.png'
import cardLager2 from '../../statics/images/NuestrasCervezas/c-2.png'
import cardLager3 from '../../statics/images/NuestrasCervezas/c-3.png'
import cardLager4 from '../../statics/images/NuestrasCervezas/c-4.png'

import cardZero1 from '../../statics/images/NuestrasCervezas/card-zero-1.png'
import cardZero2 from '../../statics/images/NuestrasCervezas/card-zero-2.png'

const ListBeerLager = [
  {
    color: 'green',
    image: cardLager1,
    title: 'Lata 350 y 470 cc.',
  },
  {
    color: 'green',
    image: cardLager2,
    title: 'Long neck 355 cc.',
  },
  {
    color: 'green',
    image: cardLager3,
    title: 'No retornable de 1 Litro.',
  },
  {
    color: 'green',
    image: cardLager4,
    title: 'Retornable de 500 cc y 1,2 lts.',
  },
]

const ListBeerZero = [
  {
    color: 'blue',
    image: cardZero1,
    type: 'Cristal Cero',
    title: 'Lata 350 cc y Long Neck 355 cc.',
  },
  {
    color: 'blue',
    image: cardZero2,
    type: 'Cristal Cero Radler',
    title: 'Radler Lata 350 cc y Long Neck 355 cc.',
  },
]

export const NuestraCerveza = () => {
  return (
    <>
      <HelmetSEO title={'Nuestra cerveza'} />

      <ValidarEdad />

      <div className="OurBeer__hero">
        <div className="OurBeer__hero__content">
          <div className="Hero__content__top"></div>

          <div className="Hero__content__bottom">
            <div className="Content__bottom__text">
              <div className="Bottom__text__left">
                <span>Nuestras Cervezas</span>
                <h1>Cristal Lager</h1>
                <p>
                  Cerveza tipo Lager muy refrescante, envasada en frío,
                  elaborada con ingredientes 100% naturales.
                </p>
              </div>

              <div className="Bottom__text__right">
                <ul>
                  <li>
                    <span>Estilo: </span>Lager
                  </li>
                  <li>
                    <span>Color: </span>Dorador brillante
                  </li>
                  <li>
                    <span>Grado Alcohólico: </span>4,6°
                  </li>
                  <li>
                    <span>Amargor: </span>17IBU
                  </li>
                  <li>
                    <span>Maltas: </span>Rubia
                  </li>
                  <li>
                    <span>Temperatura de servido: </span>6 a 8°C
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="OurBeer__lager">
        <div className="OurBeer__lager__content">
          {ListBeerLager.map((item, i) => {
            return (
              <BeerCard
                key={i}
                color={item.color}
                name={item.title}
                img={item.image}
              />
            )
          })}
        </div>
      </div>

      <div className="AboutOurBeer__lager">
        <div className="AboutOurBeer__lager__banner">
          <div className="Lager__content__title">
            <h1>Ingredientes 100% Naturales</h1>
          </div>
        </div>

        <div className="AboutOurBeer__lager__text">
          <div className="Lager__content__text">
            <p>
              Cristal no solo es una cerveza lager RErefrescante por su cuerpo
              balanceado, sino que también está hecha con ingredientes 100%
              naturales como agua, malta de cebada, lúpulo y levadura. Todos
              elementos seleccionados para que al juntarnos, sigamos aliviando
              la sed.{' '}
            </p>
          </div>
        </div>
      </div>

      <div className="AboutOurBeer__zero">
        <div className="AboutOurBeer__zero__content">
          <div className="BeerZero__content__top"></div>

          <div className="BeerZero__content__bottom">
            <div className="Content__bottom__text">
              <div className="Bottom__text__left">
                <h1>Cristal Cero y Cero Radler</h1>
                <p>
                  100% refrescante y 0,0% alcohol. Cristal Cero, refrescante en
                  toda ocasión y Cristal Cero Radler tiene jugo de limón 100%
                  natural.
                </p>
              </div>

              <div className="Bottom__text__right">
                <ul>
                  <li>
                    <span>Estilo: </span>Lager
                  </li>
                  <li>
                    <span>Color: </span>Dorador Opalecente
                  </li>
                  <li>
                    <span>Grado Alcohólico: </span>0,0°
                  </li>
                  <li>
                    <span>Amargor: </span>9IBU
                  </li>
                  <li>
                    <span>Maltas: </span>Rubia
                  </li>
                  <li>
                    <span>Temperatura de servido: </span>6 a 8°C
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="OurBeer__zero">
        <div className="OurBeer__zero__content">
          {ListBeerZero.map((item, i) => {
            return (
              <BeerCard
                key={i}
                color={item.color}
                name={item.title}
                img={item.image}
                type={item.type}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}
