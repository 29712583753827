import React from 'react'
import { isMobile } from 'react-device-detect'

import SwiperCore, { Pagination, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'swiper/swiper.scss'

import './swiper.scss'

SwiperCore.use([Pagination, A11y])

import imgLaBarra from '../../statics/images/Home/BANNERHOME_labarra.jpg'
import imgLaBarraMobile from '../../statics/images/Home/banner-labarra-mobile.jpg'
import imageMobile1 from '../../statics/images/Home/Banner-Home-425X691-PACKSHOT.jpg'
import imageDesktop1 from '../../statics/images/Home/Banner-Home-PACKSHOT.jpg'
import imageMobileRetornable from '../../statics/images/Home/Banner-HOME-MOBILE-Retornabilidad-CRISTAL_425X691.jpg'
import imageDesktopRetornable from '../../statics/images/Home/Banner-HOME-DESKTOP-Retornabilidad-CRISTAL.jpg'
import imageMBsedchile from '../../statics/images/Home/Banner-HOME-MOBILE-CRISTAL-Credenciales-2023_425X691.jpg'
import imageTabletsedchile from '../../statics/images/Home/Banner-HOME-tablet-CRISTAL-Credenciales-2023.jpg'
import imageDesksedchile from '../../statics/images/Home/Banner-Home-DESK-CRISTAL-Credenciales-2023.jpg'
import imageDeskVerano2024 from '../../statics/images/Home/Banner-Home-CRISTAL-VERANO-2024.jpg'
import imageTabletVerano2024 from '../../statics/images/Home/Banner-HOME-MOBILE-CRISTAL-VERANO-2024.jpg'
import imageMbVerano2024 from '../../statics/images/Home/Banner-HOME-MOBILE-CRISTAL-VERANO-2024_425X691.jpg'
import imageMbCopa2024 from '../../statics/images/Home/Banner-HOME-MOBILE-CRISTAL-COPA-2024_425X691-JUNIO.png'
import imageTabletCopa2024 from '../../statics/images/Home/Banner-HOME-MOBILE-CRISTAL-COPA-2024-JUNIO.png'
import imageDeskCopa2024 from '../../statics/images/Home/Banner-Home-DESKTOP-CRISTAL-COPA-2024-JUNIO.png'
import imageDeskFiestasPatrias2024 from '../../statics/images/Home/Banner-Home-desktop-Fiestas-Patrias-SELLO-CUADRADO.png'
import imageMBFiestasPatrias2024 from '../../statics/images/Home/Banner-Home-mobile-Fiestas-Patrias.png'

const ArraySlides = [
  {
    imageMobile: imageMBFiestasPatrias2024,
    imageDesktop: imageDeskFiestasPatrias2024,
  },
  {
    imageMobile: imageMbCopa2024,
    imageTable: imageTabletCopa2024,
    imageDesktop: imageDeskCopa2024,
    url: 'https://concurso.cristal.cl',
  },
  {
    imageMobile: imageMbVerano2024,
    imageTable: imageTabletVerano2024,
    imageDesktop: imageDeskVerano2024,
  },
  {
    imageMobile: imageMBsedchile,
    imageTable: imageTabletsedchile,
    imageDesktop: imageDesksedchile,
  },
  {
    imageMobile: imageMobileRetornable,
    imageTable: '',
    imageDesktop: imageDesktopRetornable,
  },
  {
    imageMobile: imageMobile1,
    imageTable: '',
    imageDesktop: imageDesktop1,
  },
  {
    imageMobile: imgLaBarraMobile,
    imageTable: '',
    imageDesktop: imgLaBarra,
    url: 'https://www.labarra.cl/4-nacionales?marca=36-cristal',
  },
  /* {
    imageMobile: imageMobile1,
    imageTable: '',
    imageDesktop: imageDesktop1,
    title: 'Juntémonos',
    subtitle: 'con una Cristal, RErefrescante.',
  } */
]

export const SwiperGallery = () => {
  return (
    <Swiper
      className="Hero__swiper"
      spaceBetween={0}
      slidesPerView={1}
      pagination={{ clickable: true }}
    >
      {ArraySlides.map((item, i) => {
        return (
          <SwiperSlide
            key={i}
            style={{
              backgroundImage: `url(${
                isMobile ? item.imageMobile : item.imageDesktop
              })`,
            }}
          >
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <div className="Hero__swiper__content">
                <div className="Swiper__content__text">
                  <h1>{item.title}</h1>
                  <h2>{item.subtitle}</h2>
                  <p>{item.text}</p>
                </div>
              </div>
            </a>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
