import React from 'react'
import { HelmetSEO } from '../../components/Helmet/HelmetSEO'
import { ValidarEdad } from '../../components/ValidarEdad/ValidarEdad'
import imgprom from '../../statics/images/TercerTiempo/imagenes.jpg'
import logo3ert from '../../statics/images/TercerTiempo/LOGO-3er-TIEMPO.png'
import logoLeague from '../../statics/images/TercerTiempo/Grupo-ligas.png'
import img1 from '../../statics/images/TercerTiempo/img-1.jpg'
import img2 from '../../statics/images/TercerTiempo/img-2.jpg'
import img3 from '../../statics/images/TercerTiempo/img-3.jpg'
import img4 from '../../statics/images/TercerTiempo/img-4.jpg'
import img5 from '../../statics/images/TercerTiempo/img-5.jpg'
import img6 from '../../statics/images/TercerTiempo/img-6.jpg'
import img7 from '../../statics/images/TercerTiempo/img-7.jpg'
import img8 from '../../statics/images/TercerTiempo/img-8.jpg'
import img9 from '../../statics/images/TercerTiempo/img-9.jpg'
import img10 from '../../statics/images/TercerTiempo/img-10.jpg'
import img11 from '../../statics/images/TercerTiempo/img-11.jpg'
import img12 from '../../statics/images/TercerTiempo/img-12.jpg'
import img13 from '../../statics/images/TercerTiempo/img-13.jpg'
import img14 from '../../statics/images/TercerTiempo/img-14.jpg'
import img15 from '../../statics/images/TercerTiempo/img-15.jpg'
import img16 from '../../statics/images/TercerTiempo/img-16.jpg'
import img17 from '../../statics/images/TercerTiempo/img-17.jpg'
import img18 from '../../statics/images/TercerTiempo/img-18.jpg'
import liga1 from '../../statics/images/TercerTiempo/LIF.png'
import liga2 from '../../statics/images/TercerTiempo/COF.png'
import liga3 from '../../statics/images/TercerTiempo/copa-Internacional.png'
import liga4 from '../../statics/images/TercerTiempo/Brisas-San-Martin.png'
import liga5 from '../../statics/images/TercerTiempo/copa-Capital.png'
import liga6 from '../../statics/images/TercerTiempo/Norte-F11.png'
import liga7 from '../../statics/images/TercerTiempo/Copa-F11.png'
import liga8 from '../../statics/images/TercerTiempo/Naciones.png'
import liga9 from '../../statics/images/TercerTiempo/Ex-alumnos-San-Ignacoi.png'
import liga10 from '../../statics/images/TercerTiempo/Nortew-Chicureo.png'
import liga11 from '../../statics/images/TercerTiempo/Derecho-UC.png'
import liga12 from '../../statics/images/TercerTiempo/Rompe-Redes.png'
import liga13 from '../../statics/images/TercerTiempo/torneo-nacional.png'
import liga14 from '../../statics/images/TercerTiempo/Condor.png'
import liga15 from '../../statics/images/TercerTiempo/Entre-Amigos.png'
import liga16 from '../../statics/images/TercerTiempo/Apoderados-San-Ignacio.png'
import liga17 from '../../statics/images/TercerTiempo/liga-70.png'
import liga18 from '../../statics/images/TercerTiempo/Master-league.png'
import liga19 from '../../statics/images/TercerTiempo/Old-School-League.png'
import './tercertiempo.scss'

export const TercerTiempo = () => {
  const ligasFutbol = [
    {
      img: liga1,
      liga: 'Liga Independiente',
      categoria: '11',
      club: 'Liga Independiente',
    },
    { img: liga2, liga: 'COF', categoria: '11', club: 'COF' },
    {
      img: liga3,
      liga: 'Internacional',
      categoria: '11',
      club: 'CADEM',
    },
    {
      img: liga4,
      liga: 'Brisas de San Martin',
      categoria: '11',
      club: 'San Martín',
    },
    {
      img: liga5,
      liga: 'Capital',
      categoria: '11',
      club: 'Lipangue',
    },
    {
      img: liga6,
      liga: 'Liga Norte',
      categoria: '11',
      club: 'Ex CD Caja 18 de septiembre',
    },
    {
      img: liga7,
      liga: 'F11',
      categoria: '11',
      club: 'Lo Espejo',
    },
    {
      img: liga8,
      liga: 'Liga Naciones',
      categoria: '11',
      club: 'Lo Espejo',
    },
    {
      img: liga9,
      liga: 'Ex San Ignacio',
      categoria: '7',
      club: 'Fortin Cruzado',
    },
    {
      img: liga10,
      liga: 'Liga Norte Chicureo',
      categoria: '7',
      club: 'Club Chileno - Fortin Cruzado',
    },
    {
      img: liga11,
      liga: 'Derecho UC',
      categoria: '7',
      club: 'Club Chileno - Fortin Cruzado',
    },
    {
      img: liga12,
      liga: './assets/img/Rompe-Redes.png',
      categoria: '7',
      club: 'Maipu - La Florida - Puente Alto',
    },
    {
      img: liga13,
      liga: 'Torneo Nacional',
      categoria: '7',
      club: 'Lonquen',
    },
    {
      img: liga14,
      liga: 'Liga Condor',
      categoria: '7',
      club: 'Terrasoccer',
    },
    {
      img: liga15,
      liga: 'Entreamigos',
      categoria: '7',
      club: 'San Ignacio - San Carlos',
    },
    {
      img: liga16,
      liga: 'San Ignacio Apoderados',
      categoria: '7',
      club: 'San Ignacio - San Carlos',
    },
    {
      img: liga17,
      liga: 'Liga 70',
      categoria: '7',
      club: 'San Ignacio - San Carlos',
    },
    {
      img: liga18,
      liga: 'Master League',
      categoria: '7',
      club: 'Maipu - La Florida - Puente Alto',
    },
    {
      img: liga19,
      liga: 'Old School League',
      categoria: '7',
      club: 'Club Oriente',
    },
  ]

  return (
    <>
      <HelmetSEO title={'TercerTiempo'} />

      <ValidarEdad />

      <div className="ThirdTime__hero">
        <div className="ThirdTime__hero__content">
          <div className="ThirdTime__content__top"></div>

          <div className="ThirdTime__content__bottom">
            <div className="Content__bottom__text">
              <div className="Bottom__text__center">
                <h1>
                  REREFRESCARNOS DESPUÉS DEL PARTIDO, ES JUNTARNOS POR LO QUE
                  NOS UNE.
                </h1>
                <p>
                  Juntémonos con el fútbol, los amigos y una Cristal bien
                  refrescante
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ThirdTime__custom">
        <div className="ThirdTime__custom__content">
          <div className="Custom__content__photo">
            <img src={imgprom} alt="" />
          </div>
          <div className="Custom__content__textInfo">
            <p>
              El Tercer Tiempo se ha transformado en parte fundamental de la
              cultura futbolera. Hoy los equipos amateurs tienen como tradición
              después de jugar un partido, disfrutar de una cerveza, un asado
              para cortar la semana o simplement JUNTARSE a disfrutar con los
              amigos.
            </p>
            <p>
              Y es desde ahí que Cristal, se ha posicionado como la cerveza
              oficial de las ligas de fútbol amateur. Ya son 8 años desde que el
              tour recorre Chile, refrescando el Tercer Tiempo de las ligas,
              equipos y jugadores del fútbol amateur.
            </p>
          </div>
        </div>
      </div>
      <div className="ThirdTime__bg">
        <div className="ThirdTime__ligas">
          <h1>
            Conoce las ligas más <span>re</span>frescantes de Chile{' '}
          </h1>
          <div className="ligas__table">
            <table>
              <tr>
                <th></th>
                <th>Liga</th>
                <th>Categoria</th>
                <th>Club</th>
              </tr>
              {ligasFutbol.map((liga, i) => (
                <tr key={i}>
                  <td>
                    <img src={liga.img} alt="liga" />
                  </td>
                  <td>{liga.liga}</td>
                  <td>Futbol {liga.categoria}</td>
                  <td>{liga.club}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>

      <div className="ThirdTime__ig">
        <div className="ThirdTime__ig__content">
          <div className="ThirdTime__ig__textTitle">
            <h1>
              <span>RE</span>REFRÉSCATE VIENDO NUESTRA GALERÍA DE IMÁGENES
            </h1>
          </div>
          <div className="ThirdTime__ig__gallery">
            <article className="igImg">
              <img src={img1} />
            </article>
            <article className="igImg">
              <img src={img2} />
            </article>
            <article className="igImg">
              <img src={img3} />
            </article>
            <article className="igImg">
              <img src={img4} />
            </article>
            <article className="igImg">
              <img src={img5} />
            </article>
            <article className="igImg">
              <img src={img6} />
            </article>
            <article className="igImg">
              <img src={img7} />
            </article>
            <article className="igImg">
              <img src={img8} />
            </article>
            <article className="igImg">
              <img src={img9} />
            </article>
            <article className="igImg">
              <img src={img10} />
            </article>
            <article className="igImg">
              <img src={img11} />
            </article>
            <article className="igImg">
              <img src={img12} />
            </article>
            <article className="igImg">
              <img src={img13} />
            </article>
            <article className="igImg">
              <img src={img14} />
            </article>
            <article className="igImg">
              <img src={img15} />
            </article>
            <article className="igImg">
              <img src={img16} />
            </article>
            <article className="igImg">
              <img src={img17} />
            </article>
            <article className="igImg">
              <img src={img18} />
            </article>
            <article className="igImgClick">
              <a href="https://www.instagram.com/cervezacristal/">
                Ver más en Instagram
              </a>
            </article>
          </div>
        </div>
      </div>

      <div className="ThirdTime__bannerLeague">
        <div className="ThirdTime__bannerLeague__content">
          <div className="ThirdTime__bannerLeague__league">
            <img src={logoLeague} alt="" />
          </div>
        </div>
      </div>

      <div className="ThirdTime__logo3er">
        <div className="ThirdTime__logo3er__content">
          <div className="ThirdTime__logo3er__logo">
            <img src={logo3ert} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}
