import React from 'react'

import { HelmetSEO } from '../../components/Helmet/HelmetSEO'

import './proceso.scss'

import moliendaImg from '../../statics/images/Proceso/molienda.svg'
import maceracionImg from '../../statics/images/Proceso/maceracion.svg'
import filtroLauterImg from '../../statics/images/Proceso/filtrolauter.svg'
import coccionImg from '../../statics/images/Proceso/coccion.svg'
import whirpoolImg from '../../statics/images/Proceso/whirpool.svg'
import enfriadoImg from '../../statics/images/Proceso/enfriado.svg'
import fermentacionImg from '../../statics/images/Proceso/fermentacion.svg'
import maduracionImg from '../../statics/images/Proceso/maduracion.svg'
import filtroImg from '../../statics/images/Proceso/filtrado.svg'
import envasadoFrioImg from '../../statics/images/Proceso/envasadoenfrio.svg'
import deliveryImg from '../../statics/images/Proceso/despacho.svg'
import puntoVentaImg from '../../statics/images/Proceso/puntoventa.svg'
import refrescarseImg from '../../statics/images/Proceso/refrescarse.svg'
import { ValidarEdad } from '../../components/ValidarEdad/ValidarEdad'

const ArrayOfProcess = [
  {
    img: moliendaImg,
    text: 'Molienda',
    description:
      'La Malta pasa a través de los rodillos del molino donde se inyecta agua cervecera y es triturada y molida. \
     El objetivo acondicionar o humedecer la malta es para flexibilizar la cáscara del grano de manera que en su paso por el molino una porción de ellas permanezca entera.',
    backgroundColor: '',
  },
  {
    img: maceracionImg,
    text: 'Maceración',
    description:
      'El proceso de maceración corresponde a un calentamiento controlado de la mezcla de malta y agua cervecera. \
      Posteriormente la mezcla se traspasa al filtro Lauter donde ocurre la filtración del mosto o al filtro Meura.',
    backgroundColor: 'gray',
  },
  {
    img: filtroLauterImg,
    text: 'Filtro lauter',
    description:
      'En  el  filtro  lauter  se  realiza  la  filtración  del  mosto. \
      Es un equipo circular que cuenta con un fondo falso compuesto de rejillas. El proceso de filtración se realiza por aproximadamente tres horas.',
    backgroundColor: 'gray',
  },
  {
    img: coccionImg,
    text: 'Cocción',
    description:
      'El  mosto  proveniente  del  calentador  de  mosto  ingresa  al  cocedor,  donde  es  calentado  hasta  alcanzar la temperatura de ebullición. \
      La ebullición del mosto tiene una duración de 60 minutos.',
    backgroundColor: 'gray',
  },
  {
    img: whirpoolImg,
    text: 'Whirpool',
    description:
      'Concluida la ebullición la cocción pasa al clarificador de mosto denominado whirlpool. \
       El  whirlpool es un recipiente circular que se mueve como remolino dejando los desechos en el centro.',
    backgroundColor: 'gray',
  },
  {
    img: enfriadoImg,
    text: 'Enfriado',
    description:
      'El mosto caliente, proveniente del whirlpool, es enfriado a una temperatura de 10°C en una enfriadera de placas',
    backgroundColor: 'green',
  },
  {
    img: fermentacionImg,
    text: 'Fermentación',
    description:
      'Los estanques de fermentación son cilíndricos y en la base cónicos. \
      Para comenzar con el proceso de fermentación se adiciona levadura cervecera al mosto. El proceso de fermentación tiene una duración promedio de 7,5 días.',
    backgroundColor: 'green',
  },
  {
    img: maduracionImg,
    text: 'Maduración',
    description:
      'El reposo corresponde al almacenamiento de la cerveza en estanques cerrados y contrapresionados con CO2 por un tiempo definido de siete días y tiene como objetivo principal la clarificación y la estabilización del sabor de la cerveza.',
    backgroundColor: 'green',
  },
  {
    img: filtroImg,
    text: 'Filtrado',
    description:
      'Proceso de filtración a temperatura menor a 0 grados celcius.',
    backgroundColor: 'green',
  },
  {
    img: envasadoFrioImg,
    text: 'Envasado en frío',
    description:
      'Aquí es cuando Cristal se envasa en frío, a bajas temperaturas, lo que la hacen muy refrescante. \
      Terminado en diferentes formatos tales como botellas de vidrio, latas y barriles de acero inoxidable.',
    backgroundColor: 'white',
  },
  {
    img: deliveryImg,
    text: 'Distribución',
    description:
      'Cristal es la cerveza de Chile, por eso la distribución es muy importante para estar presente en cada rincón del país, llegando de norte a sur y así refrescar a todos los chilenos.',
    backgroundColor: 'yellow',
  },
  {
    img: puntoVentaImg,
    text: 'Punto De venta',
    description:
      'En un bar, en un supermercado, en la botillería de tu barrio, en labarra.cl o donde quieras. Cristal llega a todas partes para que siempre te puedas refrescar.',
    backgroundColor: 'yellow',
  },
  {
    img: refrescarseImg,
    text: 'A refrescarse',
    description:
      'Y este es el punto final, el más refrescante, el momento en que sacas tu Cristal fría de un cooler y le das ese sorbo refrescante que alivia la sed junto a quienes más quieres',
    backgroundColor: 'yellow',
  },
]

export const Proceso = () => {
  const openModal = (e) => {
    const seletedStep = e.currentTarget

    const modal = seletedStep.querySelector('.Processes__content__modal')
    const floatCard = document.querySelector('.Float__card')

    if (modal.classList.contains('open')) {
      modal.classList.remove('open')
    }

    modal.classList.add('open')
    floatCard.classList.add('out')
  }

  const closeModal = () => {
    const modal = document.querySelector('.Processes__content__modal.open')
    const floatCard = document.querySelector('.Float__card')

    setTimeout(() => {
      modal.classList.remove('open')
      if (floatCard.classList.contains('out')) {
        floatCard.classList.remove('out')
      }
    }, 50)
  }

  return (
    <>
      <HelmetSEO title={'Proceso'} />

      <ValidarEdad />

      <div className="Process__hero">
        <div className="Process__hero__content">
          <div className="Process__content__tops"></div>

          <div className="Process__content__bottom">
            <div className="Content__bottom__title">
              <div className="Bottom__title__center">
                <span>¿Te has preguntado cómo se hace tu chela?</span>
                <h1>Proceso</h1>
                <p>
                  Conoce el proceso de elaboración de la chela más refrescante
                  de Chile.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Process__processes">
        <div className="Process__processes__content">
          <ul>
            {ArrayOfProcess.map((item, i) => {
              return (
                <li
                  onClick={openModal}
                  key={i}
                  className={item.backgroundColor}
                >
                  <div className="Processes__content__left">
                    <img src={item.img} alt="" />
                  </div>

                  <div className="Processes__content__right">
                    <h2>{item.text}</h2>
                    <p>{item.description}</p>
                  </div>

                  <div className="Processes__content__modal">
                    <div className="Content__modal__close">
                      <button type="button" onClick={closeModal}>
                        X
                      </button>
                    </div>

                    <div className="Content__modal__text">
                      <p>{item.description}</p>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </>
  )
}
