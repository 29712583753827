import React from 'react'
import './modal.scss'

export const ModalAntofagasta = () => {
  const handleModaDialogClick = (e) => {
    e.stopPropagation(true)
  }

  const modalClose = () => {
    const floatCard = document.querySelector('.Float__card')

    setTimeout(() => {
      if (floatCard.classList.contains('out')) {
        floatCard.classList.remove('out')
      }
    }, 50)
  }

  return (
    <div className="Modal">
      <div className="map__modal">
        <div className="map__modal__wrap">
          <a onClick={modalClose}>X</a>
          <div className="map__modal__section" onClick={handleModaDialogClick}>
            <h3>Avenida donde nos encontrarás</h3>
            <p>Av. Croacia</p>
            <p>Calle Prat</p>
          </div>
          <div className="map__modal__section" onClick={handleModaDialogClick}>
            <h3>
              Nos encontrarás del <span>11 al 15 de Enero.</span> <br />
              En los siguientes locales:
            </h3>
            <p>Toro Bravo</p>
            <p>Savach</p>
            <p>Sammy</p>
            <p>Mundo Pizza</p>
            <p>Bundes</p>
            <p>Bundes 2</p>
            <p>Rucaray Sport</p>
            <p>Rucaray</p>
            <p>Garita 21</p>
            <p>Patio 98</p>
            <p>Tribut</p>
          </div>

          <p className="droptext">(*) Horarios sujetos a modificaciones...</p>
        </div>
      </div>
    </div>
  )
}
