import React, { useEffect, useState } from 'react'

import './validar-edad.scss'

export const ValidarEdad = () => {
  const [validateAge, setValidateAge] = useState('')

  useEffect(() => {
    const lsValidateAge = localStorage.getItem('validateAge')

    const btnYes = document.getElementById('btn-yes')
    const btnNo = document.getElementById('btn-no')

    if (lsValidateAge) {
      document.querySelector('.validate-container').classList.add('out')
      document.querySelector('body').classList.remove('validate')
    }

    btnYes.addEventListener('click', (event) => {
      event.preventDefault()
      localStorage.setItem('validateAge', true)
      setValidateAge(true)
    })

    btnNo.addEventListener('click', (event) => {
      event.preventDefault()
      console.log('debes ser +18')
    })
  }, [validateAge])
  return (
    <div
      className={!validateAge ? 'validate-container' : 'validate-container out'}
    >
      <div className="validate-content">
        <div className="validate-content-image">
          <img src="" alt="" />
        </div>
        <div className="validate-content-title">
          <h1>Bienvenido!</h1>
          <h2>¿Eres mayor de edad?</h2>
        </div>

        <div className="validate-content-form">
          <form className="validate-form">
            {/* <div className="validate-content-form-input">
              <input type="text" maxLength={'2'} placeholder="DD" />
              <input type="text" maxLength={'2'} placeholder="MM" />
              <input type="text" maxLength={'4'} placeholder="YYYY" />
            </div> */}

            <div className="validate-content-form-button">
              <button type="submit" id="btn-no">
                No
              </button>
              <button type="submit" id="btn-yes">
                Si
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
