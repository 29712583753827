import React from 'react'

import { HelmetSEO } from '../../components/Helmet/HelmetSEO'

import './amigable.scss'

import despacho from '../../statics/images/Amigable/despacho.png'
import puntoventa from '../../statics/images/Amigable/encuentro.png'
import envasadoFrioImg from '../../statics/images/Proceso/envasadoenfrio.svg'
import refrescarseImg from '../../statics/images/Proceso/refrescarse.svg'
import { ValidarEdad } from '../../components/ValidarEdad/ValidarEdad'
//import 100 from '../../statics/images/Amigable/100.png';
const ArrayOfFriendly = [
  {
    img: refrescarseImg,
    text: 'RErefrescancia',
    description:
      'Alguien decide RErefrescarse con una Cristal Retornable bien helada, envasada en frío y elaborada 100% con ingredientes naturales.',
    backgroundColor: 'gray',
  },
  {
    img: despacho,
    text: 'Recolección',
    description:
      'Nuestros camiones recolectan las botellas previamente ocupadas y son transportadas hasta nuestra planta.',
    backgroundColor: 'green',
  },
  {
    img: envasadoFrioImg,
    text: 'Lavado y llenado',
    description:
      'Las botellas recolectadas pasan por un proceso de lavado y sanitizado en nuestra lavadoras, para ser llenadas nuevamente.',
    backgroundColor: 'gray',
  },

  {
    img: puntoventa,
    text: 'RErefrescancia',
    description:
      'Una vez más, alguien decide RErefrescarse con una Cristal Retornable para comenzar el ciclo desde el inicio.',
    backgroundColor: 'green',
  },
]

export const Amigable = () => {
  const openModal = (e) => {
    const seletedStep = e.currentTarget

    const modal = seletedStep.querySelector('.Steps__content__modal')
    const floatCard = document.querySelector('.Float__card')

    if (modal.classList.contains('open')) {
      modal.classList.remove('open')
    }

    modal.classList.add('open')
    floatCard.classList.add('out')
  }

  const closeModal = () => {
    const modal = document.querySelector('.Steps__content__modal.open')
    const floatCard = document.querySelector('.Float__card')

    setTimeout(() => {
      modal.classList.remove('open')
      if (floatCard.classList.contains('out')) {
        floatCard.classList.remove('out')
      }
    }, 50)
  }

  return (
    <>
      <HelmetSEO title={'Amigable'} />

      <ValidarEdad />

      <div className="Process__hero">
        <div className="Process__hero__content">
          <div className="Process__content__top"></div>

          <div className="Process__content__bottom">
            <div className="Content__bottom__text">
              <div className="Bottom__text__center">
                <h1>
                  CUIDAR EL MEDIO AMBIENTE, ES JUNTARNOS POR LO QUE NOS UNE.
                </h1>
                <p>
                  Cuidemos nuestro planeta para tener un futuro <span>RE</span>
                  sustentable
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Process__steps">
        <div className="Process__steps__content">
          <ul>
            {ArrayOfFriendly.map((item, i) => {
              return (
                <li
                  onClick={openModal}
                  key={i}
                  className={item.backgroundColor}
                >
                  <div className="Steps__content__left">
                    <img src={item.img} alt="" />
                  </div>

                  <div className="Steps__content__right">
                    <h2>{item.text}</h2>
                    <p>{item.description}</p>
                  </div>

                  <div className="Steps__content__modal">
                    <div className="Content__modal__close">
                      <button type="button" onClick={closeModal}>
                        X
                      </button>
                    </div>

                    <div className="Content__modal__text">
                      <p>{item.description}</p>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      <div className="SubFooter">
        <h1>METAS PARA LA DÉCADA DE CCU:</h1>
        <p>
          Reducir las emisiones de gases de efecto invernadero en un{' '}
          <span>50%</span>.
        </p>
        <p>
          Utilizar un <span>70%</span> de energías eléctricas de origen
          renovable.
        </p>
        <p>
          Valorizar en un <span>100%</span> los residuos industriales sólidos.
        </p>
        <p>
          Lograr que el <span>100%</span> de nuestros envases y embalajes sean
          reutilizables, reciclables y compostables.
        </p>
        <p>
          Que nuestros envases y embalajes contengan en promedio un{' '}
          <span>50%</span> de material reciclado.
        </p>
        <p>
          Reducir el consumo de agua en un <span>60%</span>
        </p>
      </div>
      {/* <div className="Click">
        <h1>
          Conoce el plan completo y RErefresquemos el planeta,{' '}
          <span>haz click acá</span>
        </h1>
      </div> */}
    </>
  )
}