import React from 'react'
import './origenes.scss'
import { ValidarEdad } from '../../components/ValidarEdad/ValidarEdad'
import { HelmetSEO } from '../../components/Helmet/HelmetSEO'
import origenesMbTop from '../../statics/images/Origenes/origenes-mb-top.png'
import origenesDeskTop from '../../statics/images/Origenes/origenes-desk-top.png'
import origenesMbBottom from '../../statics/images/Origenes/origenes-mb-bottom.png'
import origenesDeskBottom from '../../statics/images/Origenes/origenes-desk-bottom.png'
import btnPedidosya from '../../statics/images/Origenes/labarra.png'
import btnLabarra from '../../statics/images/Origenes/pedidosya.png'
import legalMb from '../../statics/images/Origenes/legal-mb.png'
import legalDesk from '../../statics/images/Origenes/legal-desk.png'

export const Origenes = () => {
  return (
    <div className="origenes">
      <HelmetSEO title={'Orígenes'} />
      <ValidarEdad />
      <div className="top">
        <img
          src={origenesMbTop}
          alt=""
          className="origenes-img origenes-img-mb origenes-img-mb-top"
        />
        <img
          src={origenesDeskTop}
          alt=""
          className="origenes-img origenes-img-desk origenes-img-desk-top"
        />
      </div>
      <div className="bottom">
        <img
          src={origenesMbBottom}
          alt=""
          className="origenes-img origenes-img-mb origenes-img-mb-bottom"
        />
        <img
          src={origenesDeskBottom}
          alt=""
          className="origenes-img origenes-img-desk origenes-img-desk-bottom"
        />
        <div className="container-buttons-legal">
          <div className="buttons">
            <a href="http://" target="_blank" rel="noopener noreferrer">
              <img src={btnPedidosya} alt="" className="button-bottom" />
            </a>

            <a
              href="https://labarra.cl/producto/12119"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={btnLabarra} alt="" className="button-top" />
            </a>
          </div>

          <div className="legal">
            <img src={legalMb} alt="" className="legal-mb" />
            <img src={legalDesk} alt="" className="legal-desk" />
          </div>
        </div>
      </div>
    </div>
  )
}
