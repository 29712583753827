import React from 'react'
import { Link } from 'react-router-dom'

import { HelmetSEO } from '../../components/Helmet/HelmetSEO'

import img404 from '../../statics/14-layers.png'

import './error-404.scss'

export const Error404 = () => {
  return (
    <>
      <HelmetSEO title={'Error 404'} />

      <div className="Error_container">
        <div className="Error_content">
          <div className="Content_text">
            <div className="Content_text_big">
              <span>404</span>
            </div>
            <div className="Content_text_normal">
              <h1>Error</h1>
              <h2>Pagina no encontrada</h2>
            </div>
            <div className="Content_text_button">
              <Link to="/">Volver al home</Link>
            </div>
          </div>

          <div className="Content_img">
            <img src={img404} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}
