import React from 'react'
import './modal.scss'

export const ModalPucon = () => {
  const handleModaDialogClick = (e) => {
    e.stopPropagation(true)
  }

  const modalClose = () => {
    const floatCard = document.querySelector('.Float__card')

    setTimeout(() => {
      if (floatCard.classList.contains('out')) {
        floatCard.classList.remove('out')
      }
    }, 50)
  }

  return (
    <div className="Modal">
      <div className="map__modal">
        <div className="map__modal__wrap">
          <a onClick={modalClose}>X</a>
          <div className="map__modal__section" onClick={handleModaDialogClick}>
            <h3>Avenida donde nos encontrarás:</h3>
            <p>Av. Bernardo Ohiggins</p>
          </div>
          <div className="map__modal__section" onClick={handleModaDialogClick}>
            <h3>
              {' '}
              Nos encontrarás del <span>7 al 14 de Febrero.</span> <br />
              En los siguientes locales:
            </h3>
            <p>Ruka Playa</p>
            <p>Krater</p>
            <p>Bar Blues</p>
            <p>Bar The Gang</p>
            <p>Lolos Burgers</p>
            <p>La Esquina</p>
            <p>Restaurant Los Lagos</p>
            <p>Restauran el uno</p>
            <p>Restaurant el uno pizza</p>
            <p>Restaurant Campos</p>
          </div>

          <p className="droptext">(*) Horarios sujetos a modificaciones...</p>
        </div>
      </div>
    </div>
  )
}
