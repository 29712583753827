import React from 'react'
import './modal.scss'

export const ModalPtoVaras = () => {
  const handleModaDialogClick = (e) => {
    e.stopPropagation(true)
  }

  const modalClose = () => {
    const floatCard = document.querySelector('.Float__card')

    setTimeout(() => {
      if (floatCard.classList.contains('out')) {
        floatCard.classList.remove('out')
      }
    }, 50)
  }

  return (
    <div className="Modal">
      <div className="map__modal">
        <div className="map__modal__wrap">
          <a onClick={modalClose}>X</a>
          <div className="map__modal__section" onClick={handleModaDialogClick}>
            <h3>Avenida donde nos encontrarás:</h3>
            <p>Vicente Perez Rosales</p>
          </div>
          <div className="map__modal__section" onClick={handleModaDialogClick}>
            <h3>
              Nos encontrarás del <span>05 al 06 de Febrero.</span> <br />
              En los siguientes locales:
            </h3>
            <p> Dreams Puerto Varas</p>
            <p>Bravo Cabrera</p>
          </div>

          <p className="droptext">(*) Horarios sujetos a modificaciones...</p>
        </div>
      </div>
    </div>
  )
}
