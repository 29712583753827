import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'

import { HelmetProvider } from 'react-helmet-async'

import { Routes } from './routes/Routes'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import './styles/styles.scss'

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <Routes />
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
