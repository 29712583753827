import React from 'react'
import PropTypes from 'prop-types'

import './beer-cards.scss'

export const BeerCard = (props) => {
  return (
    <div className={`Card__beer ${props.color}`}>
      <div className={`Card__beer__content ${props.color}`}>
        <div className="beer__content__image">
          <img src={props.img} alt="" />
        </div>
      </div>

      <div className={`Card__beer__desc ${props.color}`}>
        <div className="beer__desc__text">
          <span>{props.color === 'blue' ? props.type : ''}</span>
          <p>{props.name}</p>
        </div>
      </div>
    </div>
  )
}

BeerCard.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  type: PropTypes.string,
}
