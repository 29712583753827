import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { HelmetSEO } from '../../../components/Helmet/HelmetSEO'
import { ValidarEdad } from '../../../components/ValidarEdad/ValidarEdad'
import logo from '../../../statics/images/TeamCristal/iconobar.png'
import './map.scss'
import { ModalAntofagasta } from './ModalMap/ModalAntofagasta'
import { ModalCoquimbo } from './ModalMap/ModalCoquimbo'
import { ModalPtoVaras } from './ModalMap/ModalPtoVaras'
import { ModalPucon } from './ModalMap/ModalPucon'
import { ModalSantiago } from './ModalMap/ModalSantiago'
import { ModalViña } from './ModalMap/ModalViña'

export const Map = () => {
  // modalIquique
  /* const [modalShow1, setModalShow1] = useState(false)

  const modalSucess1 = () => {
    setModalShow1(!modalShow1)
  }

  const closeModal1 = () => {
    setModalShow1(false)
  } */

  // modalAntofagasta
  const [modalShow2, setModalShow2] = useState(false)

  const modalSucess2 = () => {
    setModalShow2(!modalShow2)
  }

  const closeModal2 = () => {
    setModalShow2(false)
  }

  // modalCoquimbo
  const [modalShow3, setModalShow3] = useState(false)

  const modalSucess3 = () => {
    setModalShow3(!modalShow3)
  }

  const closeModal3 = () => {
    setModalShow3(false)
  }

  // modalViña
  const [modalShow4, setModalShow4] = useState(false)

  const modalSucess4 = () => {
    setModalShow4(!modalShow4)
  }

  const closeModal4 = () => {
    setModalShow4(false)
  }

  // modalSantiago
  const [modalShow5, setModalShow5] = useState(false)

  const modalSucess5 = () => {
    setModalShow5(!modalShow5)
  }

  const closeModal5 = () => {
    setModalShow5(false)
  }

  // modalPucon
  const [modalShow6, setModalShow6] = useState(false)

  const modalSucess6 = () => {
    setModalShow6(!modalShow6)
  }

  const closeModal6 = () => {
    setModalShow6(false)
  }

  // modalPtoVaras
  const [modalShow7, setModalShow7] = useState(false)

  const modalSucess7 = () => {
    setModalShow7(!modalShow7)
  }

  const closeModal7 = () => {
    setModalShow7(false)
  }

  return (
    <>
      <HelmetSEO title={''} />

      <ValidarEdad />

      <div className="TeamVerano__hero">
        <div className="TeamVerano__hero__content">
          <div className="Hero__content__top"></div>

          <div className="Hero__content__bottom">
            <div className="Content__bottom__logo">
              <div className="Bottom__logo__center">
                <NavLink to="/">
                  <img loading="lazy" src={logo} alt="logo team Cristal" />
                </NavLink>
              </div>
              <div className="Bottom__logo__text">
                <h1>La Ruta del Equipo Cristal</h1>
                <p>
                  El Equipo Cristal estará en distintos bares de tu ciudad
                  haciendo dinámicas con premios para disfrutar y juntarse con
                  responsabilidad.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="TeamVerano__map">
        <div className="TeamVerano__map__wrap">
          <div className="App">
            <div className="map">
              <div className="map__wrap">
                <img
                  src="https://user-images.githubusercontent.com/55818246/147473206-65fcfc64-ce70-476c-ba00-05f8e3da3bcc.png"
                  alt=""
                />
                <div className="map__points">
                  <div
                    className={`modalSucess${modalShow2 ? ' active' : ''}`}
                    onClick={closeModal2}
                  >
                    <ModalAntofagasta />
                  </div>
                  <div
                    className="map__point map__point--antofagasta"
                    onClick={modalSucess2}
                  >
                    <h5>Antofagasta</h5>
                  </div>
                  <div
                    className={`modalSucess${modalShow3 ? ' active' : ''}`}
                    onClick={closeModal3}
                  >
                    <ModalCoquimbo />
                  </div>
                  <div
                    className="map__point map__point--coquimbo"
                    onClick={modalSucess3}
                  >
                    <h5>Coquimbo/La Serena</h5>
                  </div>
                  <div
                    className={`modalSucess${modalShow4 ? ' active' : ''}`}
                    onClick={closeModal4}
                  >
                    <ModalViña />
                  </div>
                  <div
                    className="map__point map__point--viña"
                    onClick={modalSucess4}
                  >
                    <h5>Viña del mar</h5>
                  </div>
                  <div
                    className={`modalSucess${modalShow5 ? ' active' : ''}`}
                    onClick={closeModal5}
                  >
                    <ModalSantiago />
                  </div>
                  <div
                    className="map__point map__point--santiago"
                    onClick={modalSucess5}
                  >
                    <h5>Santiago</h5>
                  </div>
                  <div
                    className={`modalSucess${modalShow6 ? ' active' : ''}`}
                    onClick={closeModal6}
                  >
                    <ModalPucon />
                  </div>
                  <div
                    className="map__point map__point--pucon"
                    onClick={modalSucess6}
                  >
                    <h5>Pucón</h5>
                  </div>
                  <div
                    className={`modalSucess${modalShow7 ? ' active' : ''}`}
                    onClick={closeModal7}
                  >
                    <ModalPtoVaras />
                  </div>
                  <div
                    className="map__point map__point--ptoVaras"
                    onClick={modalSucess7}
                  >
                    <h5>Puerto Varas</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Map__wrap__cam">
            <NavLink to="/">
              <img
                loading="lazy"
                src="https://user-images.githubusercontent.com/62721491/147454758-522f4c9d-aa45-4abc-854f-edd5fa31704f.png"
                alt="logo team Cristal"
              />
            </NavLink>
          </div>
        </div>
      </div>
      <div className="Map__wrap__footer">
        <img
          loading="lazy"
          src="https://user-images.githubusercontent.com/62721491/147702241-20125f13-b06e-49cf-b961-4019852e70b3.png"
          alt="logo team Cristal map"
        />
      </div>
    </>
  )
}
