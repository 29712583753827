import React from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet-async'

export const HelmetSEO = (props) => {
  return (
    <Helmet>
      <title>Cerveza Cristal | {props.title}</title>
    </Helmet>
  )
}

HelmetSEO.propTypes = {
  title: PropTypes.string.isRequired,
}
