import React from 'react'
import { HelmetSEO } from '../../components/Helmet/HelmetSEO'
import { ValidarEdad } from '../../components/ValidarEdad/ValidarEdad'
//import { Card } from './Card/Card'
import './juntemonos.scss'

export const Juntemonos = () => {
  return (
    <>
      <HelmetSEO title={'Juntémonos'} />

      <ValidarEdad />

      <div className="Together__hero">
        <div className="Together__hero__content">
          <div className="Together__content__top"></div>

          <div className="Together__content__bottom">
            <div className="Content__bottom__text">
              <div className="Bottom__text__center">
                <h1>JUNTÉMONOS CON LA MÚSICA</h1>
                <p>
                  Revive lo <span>RE</span>refrescante que fue disfrutar de la
                  música en el festival Cristal Juntémonos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container__videos">
        <div className="container__videos__card">
          <div className="card__video">
            <div className="wrapresponsive">
              <iframe src="https://www.youtube.com/embed/48XBEkpAYHQ"></iframe>
            </div>
          </div>
          <div className="card__text">
            <h4>Festival Cristal Juntémonos - <br/>Viña del Mar</h4>
            <h5>55.078 visualizaciones18</h5>
            <div className="card__text__info">
              <p>feb 2018</p>
            </div>
          </div>
        </div>
        <div className="container__videos__card">
          <div className="card__video">
            <div className="wrapresponsive">
              <iframe src="https://www.youtube.com/embed/48XBEkpAYHQ"></iframe>
            </div>
          </div>
          <div className="card__text">
            <h4>Festival Cristal Juntémonos - <br/>Viña del Mar</h4>
            <h5>55.078 visualizaciones18</h5>
            <div className="card__text__info">
              <p>feb 2018</p>
            </div>
          </div>
        </div>
        <div className="container__videos__card">
          <div className="card__video">
            <div className="wrapresponsive">
              <iframe src="https://www.youtube.com/embed/GTdCvlbbjWw"></iframe>
            </div>
          </div>
          <div className="card__text">
            <h4>Festival Cristal Juntémonos - <br/>Pucón</h4>
            <h5>47.659 visualizaciones16 </h5>
            <div className="card__text__info">
              <p>feb 2018</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container__video">
        <div className="container__video__card">
          <div className="card__videos">
            <div className="wrapresponsive">
              <iframe src="https://www.youtube.com/embed/lJK-0M-V6so"></iframe>
            </div>
          </div>
          <div className="card__text">
            <h4>Chancho en Piedra - Festival Cristal <br/> Juntémonos 2019</h4>
            <h5>45.140 visualizaciones</h5>
            <div className="card__text__info">
              <p>28 ene 2019</p>
            </div>
          </div>
        </div>
        <div className="container__video__card">
          <div className="card__video">
            <div className="wrapresponsive">
              <iframe  src="https://www.youtube.com/embed/UC2Tx1EnVEU"></iframe>
            </div>
          </div>
          <div className="card__text">
            <h4>Cultura Profética - Festival Cristal <br/> Juntémonos 2019</h4>
            <h5>695.582 visualizaciones</h5>
            <div className="card__text__info">
              <p>21 ene 2019</p>
            </div>
          </div>
        </div>
        <div className="container__video__card">
          <div className="card__video">
            <div className="wrapresponsive">
              <iframe src="https://www.youtube.com/embed/Ahc_K4H_oWE"></iframe>
            </div>
          </div>
          <div className="card__text">
            <h4>Rombai - Festival Cristal <br/> Juntémonos 2019</h4>
            <h5>512.089 visualizaciones</h5>
            <div className="card__text__info">
              <p>17 ene 2019</p>
            </div>
          </div>
        </div>
      </div>
      <div className="Together__sector">
       
      </div>
      <div className="Together__footer"> </div>
    </>
  )
}

