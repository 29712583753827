import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import imgFb from '../../statics/images/Header/fb-menu.png'
import imgIg from '../../statics/images/Header/ig-menu.png'

import './header.scss'

import logo from '../../statics/images/Header/Logo.png'
import HandNavigation from '../../statics/images/Header/hand-beer.png'

export const Header = () => {
  const [showHeader, setShowHeader] = useState(false)

  let toggleMenu = () => {
    setShowHeader(!showHeader)
  }

  const closeMenu = () => {
    setShowHeader(false)
  }

  return (
    <>
      <header className={`Header${showHeader ? ' active' : ''}`}>
        <div className="Header__content">
          <div className="Header__content__logo">
            <NavLink to="/">
              <img src={logo} alt="Cristal" />
            </NavLink>
          </div>

          <div className="Header__content__button">
            <button onClick={toggleMenu} type="button">
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </header>

      <nav className={`Header__navigation${showHeader ? ' active' : ''}`}>
        <div className="Header__navigation__bgImg">
          <img src={HandNavigation} alt="" />
        </div>

        <ul className="Header__navigation__links">
          <li>
            <NavLink
              exact
              activeClassName="is-active"
              onClick={closeMenu}
              to="/"
            >
              Home
            </NavLink>
          </li>

           {/* <li>
            <NavLink
              activeClassName="is-active"
              onClick={closeMenu}
              to="/teamverano"
            >
              Equipo Cristal
            </NavLink>
          </li> */}

          <li>
            <NavLink
              activeClassName="is-active"
              onClick={closeMenu}
              to="/nuestra-cerveza"
            >
              Nuestra cerveza
            </NavLink>
          </li>

          <li>
            <NavLink
              activeClassName="is-active"
              onClick={closeMenu}
              to="/campanias"
            >
              Campañas
            </NavLink>
          </li>

          <li>
            <NavLink
              activeClassName="is-active"
              onClick={closeMenu}
              to="/proceso"
            >
              Proceso
            </NavLink>
          </li>

          <li>
            <NavLink
              activeClassName="is-active"
              onClick={closeMenu}
              to="/historia"
            >
              Historia
            </NavLink>
          </li>

          {/* <li>
            <NavLink activeClassName="is-active" to="/juntemosnos">Juntemosnos</NavLink>
          </li> */}

          {/* <li>
            <NavLink
              activeClassName="is-active"
              onClick={closeMenu}
              to="/contacto"
            >
              Contacto
            </NavLink>
          </li> */}
          <li>
            <a href="https://www.facebook.com/CervezaCristalOficial">
              <img className="fb" src={imgFb} alt="" />
            </a>
            <a href="https://www.instagram.com/cervezacristal/">
              <img className="ig" src={imgIg} alt="" />
            </a>
          </li>
        </ul>
      </nav>
    </>
  )
}
