import React from 'react'
import './modal.scss'

export const ModalSantiago = () => {
  const handleModaDialogClick = (e) => {
    e.stopPropagation(true)
  }

  const modalClose = () => {
    const floatCard = document.querySelector('.Float__card')

    setTimeout(() => {
      if (floatCard.classList.contains('out')) {
        floatCard.classList.remove('out')
      }
    }, 50)
  }

  return (
    <div className="Modal">
      <div className="map__modal">
        <div className="map__modal__wrap">
          <a onClick={modalClose}>X</a>
          <div
            className="map__modal__section"
            onClick={handleModaDialogClick}
          ></div>
          <div className="map__modal__section" onClick={handleModaDialogClick}>
            <h3>
              Nos encontrarás del <span>02 al 03 de Febrero.</span> <br />
              En los siguientes locales:
            </h3>
            <p>El Galeón</p>
            <p>La Piojera</p>
            <p>Marco Polo</p>
            <p>Faisan Dior</p>
            <p>Bar Santiago</p>
            <p>Donde Zacarias</p>
            <p>Bar Santiago</p>
            <p>Medio Kilo</p>
          </div>

          <p className="droptext">(*) Horarios sujetos a modificaciones...</p>
        </div>
      </div>
    </div>
  )
}
