import React from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import ScrollToTop from '../components/ScrollTop/ScrollToTop'

import { Header } from '../components/Header/Header'
import { Footer } from '../components/Footer/Footer'
import { FloatCard } from '../components/FloatCard/FloatCard'

import { Home } from '../layouts/Home/Home'
import { NuestraCerveza } from '../layouts/NuestraCerveza/NuestraCerveza'
import { Campanias } from '../layouts/Campanias/Campanias'
import { Proceso } from '../layouts/Proceso/Proceso'
import { Historia } from '../layouts/Historia/Historia'
import { Juntemonos } from '../layouts/Juntemonos/Juntemonos'
//import { Contacto } from '../layouts/Contacto/Contacto'
//import { EquipoCristal } from '../layouts/TeamVerano/EquipoCristal/EquipoCristal'
import { Map } from '../layouts/TeamVerano/Map/Map'
import { Amigable } from '../layouts/Amigable/Amigable'
import { Error404 } from '../layouts/error404/error404'
import { TeamForm } from '../layouts/TeamVerano/TeamForm/TeamForm'
import { Roja } from '../layouts/Roja/Roja'
import { TercerTiempo } from '../layouts/TercerTiempo/TercerTiempo'
import { Origenes } from '../layouts/Origenes/Origenes'

export const Routes = () => {
  return (
    <Router>
      <Header />
      <FloatCard />

      <ScrollToTop />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route exact path="/nuestra-cerveza">
          <NuestraCerveza />
        </Route>
{/* 
        <Route exact path="/teamverano">
          <EquipoCristal />
        </Route> */}

        <Route exact path="/campanias">
          <Campanias />
        </Route>

        <Route exact path="/proceso">
          <Proceso />
        </Route>

        <Route exact path="/historia">
          <Historia />
        </Route>

        <Route path="/juntemonos">
          <Juntemonos />
        </Route>

        <Route path="/amigable">
          <Amigable />
        </Route>

        <Route path="/la-roja">
          <Roja />
        </Route>

        <Route path="/tercer-tiempo">
          <TercerTiempo />
        </Route>

       {/*  <Route exact path="/contacto">
          <Contacto />
        </Route> */}

        <Route exact path="/ruta-del-equipo-cristal">
          <Map />
        </Route>

        <Route exact path="/concurso-team-verano">
          <TeamForm />
        </Route>

        <Route
          path="/pongamoselhombro"
          component={() => {
            const urlParams = window.location.href
            const token = urlParams.split('.php?')[1]
            window.location.href = `https://pongamoselhombro.cl/encuesta.php?${token}`
            return null
          }}
        />

        <Route exact path="/origenes">
          <Origenes />
        </Route>

        <Route>
          <Error404 />
        </Route>
      </Switch>

      <Footer />
    </Router>
  )
}
