import React from 'react'
import { HelmetSEO } from '../../components/Helmet/HelmetSEO'
import { ValidarEdad } from '../../components/ValidarEdad/ValidarEdad'
import feed from '../../statics/images/Roja/feedinstagram.png'
import logos from '../../statics/images/Roja/logos-cristal-laroja.png'
import chile from '../../statics/images/Roja/chile.png'
import argentina from '../../statics/images/Roja/argentina.png'
import bolivia from '../../statics/images/Roja/bolivia.png'
import brasil from '../../statics/images/Roja/brasil.png'
import uruguay from '../../statics/images/Roja/uruguay.png'
import mapqatar from '../../statics/images/Roja/mapa-qatar.png'
import './roja.scss'

export const Roja = () => {
  return (
    <>
      <HelmetSEO title={'La Roja'} />

      <ValidarEdad />

      <div className="LaRoja__hero">
        <div className="LaRoja__hero__content">
          <div className="Hero__content__top"></div>

          <div className="Hero__content__bottom">
            <div className="Content__bottom__center">
              <div className="Bottom__center__logos">
                <img src={logos} alt="Logos" />
              </div>
              <div className="Bottom__center__mapqatar">
                <img src={mapqatar} alt="Mapa camino a Qatar" />
              </div>

              <div className="Bottom__center__photo">
                <p>
                  SUBE TU FOTO ALENTANDO A LA ROJA, ETIQUETANOS @cervezacristal
                </p>
                <p>
                  Y PARTICIPA POR UN AÑO DE CRISTAL PARA SEGUIR REFRESCANDO LA
                  SED DE CHILE
                </p>
                <h1></h1>
                <img src={feed} alt="Feed de Instagram" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="LaRoja__game">
        <div className="LaRoja__title">
          <h3>La ruta de La Roja a Qatar 2022</h3>
          <p>Sigue los próximos resultados de Chile rumbo al mundial</p>
        </div>
        <div className="LaRoja__game__content">
          <div className="change--1">
            <div className="change__content">
              <div className="change__content--left">
                <div className="change__content--top">
                  <img src={chile} alt="" />
                  <span>1</span>
                </div>
                <p className="change__content--bottomL">Jueves 27-01, 21:15</p>
              </div>
              <div className="change__content--right">
                <div className="change__content--top">
                  <span className="change__winner">2</span>
                  <img src={argentina} alt="" />
                </div>
                <p className="change__content--bottomR">
                  Estadio zorros del desierto
                </p>
              </div>
            </div>
          </div>
          <div className="change--2">
            <div className="change__content">
              <div className="change__content--left">
                <div className="change__content--top">
                  <img src={bolivia} alt="" />
                  <span>2</span>
                </div>
                <p className="change__content--bottomL">Martes 01-02, 17:00</p>
              </div>
              <div className="change__content--right">
                <div className="change__content--top">
                  <span className="change__winner">3</span>
                  <img src={chile} alt="" />
                </div>
                <p className="change__content--bottomR">
                  Estadio Club Atlético Morejón
                </p>
              </div>
            </div>
          </div>
          <div className="change--3">
            <div className="change__content">
              <div className="change__content--left">
                <div className="change__content--top">
                  <img src={brasil} alt="" />
                  <span>0</span>
                </div>
                <p className="change__content--bottomL">Jueves 24-03, 21:15</p>
              </div>
              <div className="change__content--right">
                <div className="change__content--top">
                  <span>0</span>
                  <img src={chile} alt="" />
                </div>
                <p className="change__content--bottomR">Estadio Maracaná</p>
              </div>
            </div>
          </div>
          <div className="change--4">
            <div className="change__content">
              <div className="change__content--left">
                <div className="change__content--top">
                  <img src={chile} alt="" />
                  <span>0</span>
                </div>
                <p className="change__content--bottomL">Martes 29-03, 21:15</p>
              </div>
              <div className="change__content--right">
                <div className="change__content--top">
                  <span>0</span>
                  <img src={uruguay} alt="" />
                </div>
                <p className="change__content--bottomR">
                  Estadio Nacional de Chile
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="LaRoja__footer"></div>
    </>
  )
}
