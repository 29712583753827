import React from 'react'
import { Link } from 'react-router-dom'

import { HelmetSEO } from '../../components/Helmet/HelmetSEO'
import { SwiperGallery } from '../../components/Swiper/Swiper'

import { ValidarEdad } from '../../components/ValidarEdad/ValidarEdad'

import './home.scss'

import card1 from '../../statics/images/Home/cerveza.png'
import card2 from '../../statics/images/TercerTiempo/banner-3ertiempo.jpg'
import card3 from '../../statics/images/Home/card-3.png'
import card4 from '../../statics/images/Roja/banner-laroja.png'
//import card5 from '../../statics/images/Home/card-5.png'
import card6 from '../../statics/images/Home/vasocerveza.png'
import card7 from '../../statics/images/Home/card-7.png'
import card8 from '../../statics/images/Historia/Fabrica.jpeg'
import card9 from '../../statics/images/Home/retornablehome.jpg'

const ArrayCards = [
  {
    bgImage: card3,
    badge: 'Proceso',
    title: 'Conoce como hacemos la cerveza',
    url: '/proceso',
  },

  /*  {
    bgImage: card5,
    badge: 'Nuestras cervezas',
    title: 'Las cervezas más refrescante',
    url: '/nuestra-cerveza',
  }, */
  {
    bgImage: card9,
    badge: 'Retornable',
    title: 'Amigable con el medio ambiente',
    url: '/amigable',
  },
  {
    bgImage: card6,
    badge: 'Nuestras cervezas',
    title: 'Conoce nuestra variedad de cervezas',
    url: '/nuestra-cerveza',
  },
  {
    bgImage: card7,
    badge: 'Festivales',
    title: 'Juntémonos con la mejor música',
    url: '/juntemonos',
  },
  {
    bgImage: card1,
    badge: 'Campañas',
    title: 'Revisa nuestra última campaña y las anteriores',
    url: '/campanias',
  },
  {
    bgImage: card8,
    badge: 'Historia',
    title: 'Nuestra cerveza desde 1850',
    url: '/historia',
  },
  {
    bgImage: card4,
    badge: 'Fútbol',
    title: 'La Roja',
    url: '/la-roja',
    red: true,
  },
  {
    bgImage: card2,
    badge: 'Tercer Tiempo',
    title: 'Refrescando la sed de los hinchas',
    url: '/tercer-tiempo',
  },
]

export const Home = () => {
  return (
    <>
      <HelmetSEO title={'Home'} />

      <ValidarEdad />

      <div className="Home__hero">
        <div className="Home__hero__swiper">
          <SwiperGallery />
        </div>
      </div>

      <div className="Home__gallery">
        <div className="Home__gallery__content">
          <div className="Gallery__content__grid">
            {ArrayCards.map((item, i) => {
              return (
                <Link
                  key={i}
                  to={item.url}
                  className={`Content__grid__card ${item.red ? 'red' : ''}`}
                  style={{ backgroundImage: `url(${item.bgImage})` }}
                >
                  <div className="Grid__card__text">
                    <div className="Card__text__badge">
                      <label>{item.badge}</label>
                    </div>

                    <h1>{item.title}</h1>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
