import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import './footer.scss'
import { BaseModal } from './Bases/BaseModal'
import imgFloar from '../../statics/images/Footer/hand-beer.png'
import logo from '../../statics/images/Footer/Logo-horizontal.png'
import imgFb from '../../statics/images/Footer/icon-fb.png'
import imgIg from '../../statics/images/Footer/icon-ig.png'
import imgYt from '../../statics/images/Footer/icon-yt.png'

export const Footer = () => {
  //modal
  const [modalShow, setModalShow] = useState(false)

  const modalBase = () => {
    setModalShow(!modalShow)
  }

  const closeModal = () => {
    setModalShow(false)
  }

  return (
    <footer className="Footer">
      <div className="Footer__content">
        <div className="Footer__content__primary">
          <div className="Content__primary__text">
            <img
              width="151"
              alt="la-barra"
              src="https://user-images.githubusercontent.com/62721491/147679237-98246acc-c0b4-430f-a66b-778db34f5fce.png"
            ></img>
            <h3>¿Quieres comprar unas Cristal?</h3>
            <a href="https://www.labarra.cl/4-nacionales?marca=36-cristal">
              Entra aquí
            </a>
          </div>

          <div className="Content__primary__image">
            <img src={imgFloar} alt="" />
          </div>
        </div>

        <div className="Footer__content__secondary">
          <div className="Content__secondary__menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/nuestra-cerveza">Nuestra Cerveza</Link>
              </li>
              <li>
                <Link to="/seleccion">Selección</Link>
              </li>
              <li>
                <Link to="/proceso">Proceso</Link>
              </li>
              <li>
                <Link to="/historia">Historia</Link>
              </li>
              {/* <li>
                <Link to="/contacto">Contacto</Link>
              </li> */}
            </ul>
          </div>

          <div className="Content__secondary__logo">
            <img src={logo} alt="" />
            <p>
              © 2021 Cerveza Cristal. <br /> Todos los derechos reservados
            </p>

            <div
              className={`modalSucess${modalShow ? ' active' : ''}`}
              onClick={closeModal}
            >
              <BaseModal />
            </div>

            <button onClick={modalBase}>Bases y Condiciones</button>
          </div>

          <div className="Content__secondary__rrss">
            <ul>
              <li>
                <a href="https://www.facebook.com/CervezaCristalOficial">
                  <img src={imgFb} alt="" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/cervezacristal/">
                  <img src={imgIg} alt="" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/cervezacristal">
                  <img src={imgYt} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}
