import React from 'react'

import './float-card.scss'

import floatBeer from '../../statics/images/Header/float-beer.png'

export const FloatCard = () => {
  return (
    <div className="Float__card">
      <div className="Float__card__square">
        <a
          href="https://www.labarra.cl/4-nacionales?marca=36-cristal"
          target="_blank"
          rel="no-rel noreferrer"
        >
          Compra <br /> aquí
        </a>
        <label>La Barra</label>
      </div>

      <div className="Float__card__circle">
        <img src={floatBeer} alt="" />
      </div>
    </div>
  )
}
